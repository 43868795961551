
import { debounce } from "lodash";
import UiButton from "~/components/UI/UiButton";
import useEvents from "~/mixins/useEvents";
export default {
  name: "GlobalSearchPopup",
  components: { UiButton },
  mixins: [useEvents],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: "",
/*      suggestions: null,
      searchRes: null, */
      searchDebounce: () => {},
    };
  },
  watch: {
    value: {
      handler(val) {
        if (!process.client) return;
        if (val) {
          document.body.style.overflow = "hidden";
          this.$nextTick(() => {
            this.$refs["search-input"].focus();
          });
        } else {
          document.body.style.overflow = "";
        }
      },
      immediate: true,
    },
    "$route.query": {
      handler(val) {
        if (!val.search) {
          this.search = "";
        }
      },
      deep: true,
    },
    search: {
      handler(val) {
        if (!val || val?.length < 3) return;
        this.searchDebounce(val);
      },
      immediate: true,
    },
  },
  created() {
    this.search = this.$route.query.search;
  },
  mounted() {
    this.searchDebounce = debounce((val) => {
      this.searchEvent(val);
    }, 400);
    document.addEventListener("keyup", this.onKeyUp);
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.onKeyUp);
  },
  methods: {
    closeModal() {
      this.$emit("input", false);
    },
    onKeyUp(evt) {
      if (evt.keyCode === 27) {
        this.closeModal();
      }
    },
    onSubmit() {
      if (this.$route.path.includes("catalog")) {
        const query = { page: 1, search: this.search };
        if (this.$route.query.sortBy) {
          query.sortBy = this.$route.query.sortBy;
        }
        this.$router
          .replace(
            // this.localePath({
            //   query: { ...this.$route.query, page: 1, search: this.search },
            // })
            this.localePath({
              query,
            })
          )
          ?.catch(() => {});
      } else {
        this.$router
          .push(this.localePath(`/catalog?search=${this.search}`))
          ?.catch(() => {});
      }

      this.closeModal();

      /* if (!this.search) return;
      try {
        const { data } = await this.$api.search.search({
          query: this.search,
        });

        this.searchRes = data.data.products;
        console.log(data.data);
      } catch (e) {
        console.error(e);
      } */
    },
    onChange(e) {
      if (!e.target.value) {
        const newQuery = JSON.parse(JSON.stringify(this.$route.query));
        delete newQuery.search;
        this.$router.replace(
          this.localePath({
            query: newQuery,
          })
        );
      }

      /* if (this.search && this.search.length >= 3) {
        try {
          const { data } = await this.$api.search.searchSuggestions({
            query: this.search,
          });

          this.suggestions = data.data.suggestions;
          console.log(data.data);
        } catch (e) {
          console.error(e);
        }
      } */
    },
    clearSearch() {
      this.search = "";
      /* this.suggestions = null;
      this.searchRes = null; */
      const newQuery = JSON.parse(JSON.stringify(this.$route.query));
      delete newQuery.search;
      this.$router.replace(
        this.localePath({
          query: newQuery,
        })
      );
      this.closeModal();
    },
  },
};
