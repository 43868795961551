import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      integrationCallbackStatuses: "general/getIntegrationCallbackStatuses",
    }),
    storeSettings() {
      return this.$store.state.general.settings;
    },
    isFacebookPixelAvailable() {
      return (
        this.integrationCallbackStatuses.TYPE_FACEBOOK_PIXEL && window?.fbq
      );
    },
  },
  methods: {
    fbPriceFormat(price) {
      price = price || 0;
      return Number(price).toFixed(2);
    },
    fbViewContentEvent(data, configurationData) {
      if (!this.isFacebookPixelAvailable || !data) {
        return;
      }
      window.fbq("track", "ViewContent", {
        content_type: "product",
        content_ids: [configurationData?.configuration_id],
        content_name: data?.mixedTranslation?.title || data?.title || "",
        content_category: data.collections?.[0]?.mixedTranslation?.title || "",
        value: this.fbPriceFormat(configurationData?.cost || data.base_cost),
        currency: this.storeSettings?.currency_code || "",
      });
    },
    fbAddToCartEvent(data, configurationData) {
      if (!this.isFacebookPixelAvailable || !data) {
        return;
      }
      window.fbq("track", "AddToCart", {
        content_type: "product",
        content_ids: [configurationData?.configuration_id],
        content_name: data?.mixedTranslation?.title || data?.title || "",
        content_category: data.collections?.[0]?.mixedTranslation?.title || "",
        value: this.fbPriceFormat(configurationData?.cost || data.base_cost),
        currency: this.storeSettings?.currency_code || "",
      });
    },
    fbInitiateCheckoutEvent(data) {
      if (!this.isFacebookPixelAvailable || !data) {
        return;
      }
      window.fbq("track", "InitiateCheckout", {
        content_type: "product",
        content_name: this.$t("checkout"),
        value: this.fbPriceFormat(data.final_amount),
        currency: this.storeSettings?.currency_code || "",
        num_items: data.items.reduce((acc, item) => acc + item.quantity, 0),
        content_ids: data.items?.map((item) => item.configuration_id),
      });
    },
    fbPurchaseEvent(data) {
      if (!this.isFacebookPixelAvailable || !data) {
        return;
      }
      window.fbq("track", "Purchase", {
        content_type: "product",
        content_name: this.$t("thanksOrder"),
        value: this.fbPriceFormat(data.final_amount),
        currency: data.currency_code,
        num_items: data.order_products.reduce(
          (acc, item) => acc + item.quantity,
          0
        ),
        content_ids: data.order_products?.map((item) => item.configuration_id),
      });
    },
  },
};
