
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import MenuBlock from "./MenuBlock.vue";
import GlobalSearchPopup from "~/components/popups/GlobalSearchPopup";
import CartDrawer from "~/components/common/CartDrawer/CartDrawer";
import { getElementHeight, topMenuFormatter } from "~/utils";
import LanguageSwitcher from "~/components/common/LanguageSwitcher.vue";
import MobileMenu from "~/components/common/MobileMenu.vue";

export default {
  components: {
    LanguageSwitcher,
    CartDrawer,
    GlobalSearchPopup,
    MenuBlock,
    MobileMenu,
  },
  data() {
    return {
      searchPopup: false,
      headerMenu: [],
      isSticky: false,
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      cartItemsCount: "cart/getCartItemsCount",
      cartInfo: "cart/getCartInfo",
      getLanguages: "general/getLanguages",
    }),
    ...mapState({
      activeMenu: (state) => state.general.desktopMenu,
      settings: (state) => state.general.settings,
    }),
  },
  created() {
    if (this.$store.state.general?.menu?.header?.items) {
      this.headerMenu = topMenuFormatter(
        this.$store.state.general?.menu?.header?.items
      );
    }
  },
  mounted() {
    const stickyElm = document.querySelector(".sticky");
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.intersectionRatio < 1) {
          this.isSticky = true;
        } else {
          this.isSticky = false;
        }
      },
      { threshold: [1] }
    );

    observer.observe(stickyElm);
  },
  methods: {
    ...mapMutations({
      toggleCartDrawer: "general/TOGGLE_CART_DRAWER",
      toggleMenu: "general/TOGGLE_SIDE_MENU",
      setDesktopMenu: "general/SET_DESKTOP_MENU",
    }),
    ...mapActions({
      removeToken: "auth/removeToken",
      resetCart: "resetCart",
      resetWishList: "resetWishList",
      resetCompareList: "resetCompareList",
      reinitData: "nuxtClientInit",
    }),
    openSearchPopup() {
      this.searchPopup = true;
    },
    async logout() {
      try {
        await this.$api.auth.signOut();
      } catch (e) {
        console.log(e);
      } finally {
        await this.removeToken();
        await this.resetCart();
        await this.resetWishList();
        await this.resetCompareList();
        await this.reinitData();
        if (this.localePath(this.$route.path) !== this.localePath("/")) {
          await this.$router.replace(this.localePath("/"));
        }
      }
    },
    getElementHeight,
    onMouseOver(identification) {
      const el = document.querySelector(".profile-dropdown");
      if (el) {
        el.style.right = "unset";
        const bounders = el.getBoundingClientRect();
        if (bounders.right > window.innerWidth) {
          el.style.right = 0;
        }
      }
      this.setDesktopMenu(identification);
    },
    onMouseLeave() {
      this.setDesktopMenu(null);
    },
  },
};
