
import { format, addDays } from "date-fns";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import CartProductCard from "~/components/common/CartDrawer/CartProductCard";
import googleAnalyticsEvents from "~/mixins/googleAnalyticsEvents";
import facebookPixelEvents from "~/mixins/facebookPixelEvents";
import useEvents from "~/mixins/useEvents";

export default {
  name: "CartDrawer",
  components: {
    CartProductCard,
  },
  mixins: [googleAnalyticsEvents, facebookPixelEvents, useEvents],
  computed: {
    ...mapState({
      isOpenCartDrawer: (state) => state.general.isOpenCartDrawer,
    }),
    ...mapGetters({
      cartInfo: "cart/getCartInfo",
      cartItemsCount: "cart/getCartItemsCount",
      cartId: "getCartId",
      getSettings: "general/getSettings",
      getSystemDateFormat: "general/getSystemDateFormat",
    }),
    estimatedDeliveryDate() {
      const isBriProductPresent = this.cartInfo?.items?.some((item) =>
        item.configuration_id?.toLowerCase()?.startsWith("bri")
      );
      const defaultShippingTerm =
        this.getSettings?.default_shipping_term ||
        this.getSettings?.default_shipping_term === 0
          ? Number(this.getSettings.default_shipping_term)
          : 32;
      const longShippingTerm =
        this.getSettings?.long_shipping_term ||
        this.getSettings?.long_shipping_term === 0
          ? Number(this.getSettings.long_shipping_term)
          : 38;
      const deliveryDays = isBriProductPresent
        ? longShippingTerm
        : defaultShippingTerm;
      return format(
        addDays(new Date(), deliveryDays),
        this.getSystemDateFormat
      );
    },
  },
  watch: {
    isOpenCartDrawer: {
      handler(val) {
        if (!process.client) return;
        if (val) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "";
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations({
      toggleCartDrawer: "general/TOGGLE_CART_DRAWER",
      closeCartDrawer: "general/CLOSE_CART_DRAWER",
      setCartInfo: "cart/SET_CART_INFO",
    }),
    ...mapActions({
      updateCartItem: "cart/updateCartItem",
      removeCartItem: "cart/removeCartItem",
    }),
    removeCartProduct(event, item, index) {
      this.removeCartItem(event);
      this.gaRemoveFromCartEvent(item, index);
      this.removeFromCartEvent({
        product_id: item.product_id,
        configuration_id: item.configuration_id,
        collection_id: item.collections?.[0]?.id || "",
        product_sku: item.vendor_model,
        product_price: item.base_cost,
      });
    },
    closeCartDrawerAndSendStatistic() {
      this.closeCartDrawer();
      this.gaBeginCheckoutEvent(this.cartInfo?.items);
      this.fbInitiateCheckoutEvent(this.cartInfo);
      this.$router.push(this.localePath(`/checkout/${this.cartId}`));
    },
  },
};
